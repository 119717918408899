import { withRouter, Link } from "react-router-dom";
import Oconfirm from "../image/icons/orderc.png";
import Navbar from "./navbar.js";
import Bottomnav from "./bottomnav";
import Navbar2 from "./navbar2.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import ReactPixel from 'react-facebook-pixel';
import { useEffect } from "react";

function Confirm() {
  let history = useHistory();
  useEffect(()=>{
    const options = {
      autoConfig: true, // Set to false if you manually configure the pixel
      debug: false, // Set to true to log events in the console
    };
    
    // Initialize Pixel (Call this in the main entry point of your app, e.g., App.js)
    ReactPixel.init('1366042074838597', {}, options);
    
    ReactPixel.track('Purchase');
    ReactPixel.track('AddPaymentInfo');
  },[])
  return (
    <>
      <Navbar />
      <Navbar2 />
      <center class="mt-5">
        <img src={Oconfirm} class="orderc mt-5" />
        <br></br>
        <button
        onClick={()=> history.push("/")}
        style={{
          backgroundColor: "#4CAF50",
          border: "none",
          color: "white",
          padding: "10px 20px",
          textAlign: "center",
          textDecoration: "none",
          display: "inline-block",
          fontSize: "16px",
          margin: "10px",
          cursor: "pointer",
          borderRadius: "12px"
        }}
      >
        Shop Again
      </button>
      </center>
      
      <br/>

      <center>
      {/* <button
        onClick={()=> window.location = "https://arfin-hayet.vercel.app/"}
        style={{
          backgroundColor: "#F40200",
          border: "none",
          color: "white",
          padding: "10px 20px",
          textAlign: "center",
          textDecoration: "none",
          display: "inline-block",
          fontSize: "16px",
          margin: "10px",
          cursor: "pointer",
          borderRadius: "12px"
        }}
      >
        Get your own e-commerce website like this
      </button> */}

      {/* Developed by <a href="https://arfin-hayet.vercel.app">Arfin Hayet</a> */}
      </center>

      <Bottomnav />
    </>
  );
}

export default Confirm;
